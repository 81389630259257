.logged-profile-container {
  display: flex;
  height: calc(100vh - 120px);
  margin-left: 96px;
  margin-right: 96px;
  overflow: auto;
  justify-content: center;
  .profile-container-layout {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    max-height: 600px;
    padding: 20px 30px;
    border-radius: 16px;
    box-shadow: 0px 12px 24px 0px #b8c3f12e;
    border: 1px solid #eef0f2;
    background: #ffffff1a;
    .profile-header {
      display: flex;
      margin-bottom: 20px;
      .profile-picture {
        margin-right: 20px;
        img {
          height: 100px;
          width: 100px;
        }
      }
      .login-vc-success-header {
        .login-vc-success-header-text {
          font-family: monospace;
          font-size: 24px;
          letter-spacing: -0.04em;
          color: #1e3079;
          font-weight: 700;
        }
        .login-vc-success-header-sub-text {
          font-family: monospace;
          font-size: 14px;
          letter-spacing: -0.04em;
          color: #1e3079;
          font-weight: 400;
        }
      }
    }

    .profile-body {
      .account-details-section {
        .account-details-title {
          font-family: monospace;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: -0.02em;
          color: #1e3079;
        }
        hr {
          border: 1px solid #dbdeea;
        }
        .account-details-name,
        .account-details-ac-number {
          display: flex;
          margin-top: 20px;
          .details-name {
            display: flex;
            font-family: monospace;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.02em;
            color: #1e3079;
            align-items: center;
            padding-left: 8px;
            width: 30%;
          }
          .details-name-value {
            display: flex;
            font-family: monospace;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.02em;
            width: 60%;
            color: #1e3079;
            align-items: center;
            justify-content: end;
          }
        }
      }
      .contact-details-section {
        margin-top: 20px;
        .contact-details-title {
          font-family: monospace;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: -0.02em;
          color: #1e3079;
        }
        hr {
          border: 1px solid #dbdeea;
        }
        .contact-details-phone,
        .contact-details-email,
        .contact-details-location {
          display: flex;
          margin-top: 20px;
          .details-name {
            display: flex;
            font-family: monospace;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.02em;
            color: #1e3079;
            align-items: center;
            padding-left: 8px;
            width: 30%;
          }
          .details-name-value {
            display: flex;
            font-family: monospace;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.02em;
            width: 60%;
            color: #1e3079;
            align-items: center;
            justify-content: end;
            text-align: right;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .logged-profile-container {
    margin-left: 48px !important;
    margin-right: 48px !important;
    height: calc(100vh - 200px) !important;
    .profile-container-layout {
      .profile-header {
        .profile-picture {
          img {
            height: 80px;
            width: 80px;
          }
        }
        .login-vc-success-header {
          .login-vc-success-header-text {
            font-size: 20px !important;
          }
          .login-vc-success-header-sub-text {
            font-size: 12px !important;
          }
        }
      }
      .profile-body {
        .account-details-section {
          .account-details-title {
            font-size: 20px !important;
          }
          .account-details-name,
          .account-details-ac-number {
            display: flex;
            margin-top: 20px;
            .details-name {
              font-size: 14px !important;
            }
            .details-name-value {
              font-size: 14px !important;
            }
          }
        }
        .contact-details-section {
          margin-top: 20px;
          .contact-details-title {
            font-size: 20px !important;
          }
          .contact-details-phone,
          .contact-details-email,
          .contact-details-location {
            display: flex;
            margin-top: 20px;
            .details-name {
              font-size: 14px !important;
            }
            .details-name-value {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .logged-profile-container {
    margin-left: 48px !important;
    margin-right: 48px !important;
    height: calc(100vh - 200px) !important;
    .profile-container-layout {
      .profile-header {
        .profile-picture {
          img {
            height: 60px;
            width: 60px;
          }
        }
        .login-vc-success-header {
          .login-vc-success-header-text {
            font-size: 16px !important;
          }
          .login-vc-success-header-sub-text {
            font-size: 12px !important;
          }
        }
      }
      .profile-body {
        .account-details-section {
          .account-details-title {
            font-size: 18px !important;
          }
          .account-details-name {
            display: flex;
            margin-top: 20px;
            .details-name {
              font-size: 12px !important;
              width: 32% !important;
            }
            .details-name-value {
              font-size: 12px !important;
              padding-left: 10px;
            }
          }

          .account-details-ac-number {
            display: flex;
            margin-top: 20px;
            .details-name {
              font-size: 12px !important;
              width: 32% !important;
            }
            .details-name-value {
              font-size: 12px !important;
              padding-left: 10px;
            }
          }
        }
        .contact-details-section {
          margin-top: 20px;
          .contact-details-title {
            font-size: 18px !important;
          }
          .contact-details-phone,
          .contact-details-email,
          .contact-details-location {
            display: flex;
            margin-top: 20px;
            .details-name {
              font-size: 12px !important;
              width: 32% !important;
            }
            .details-name-value {
              font-size: 12px !important;
              padding-left: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .logged-profile-container {
    margin-left: 12px !important;
    margin-right: 12px !important;
    .profile-container-layout {
      padding: 20px 10px !important;
    }
  }
}
