@import '../../common/baseStyle/base.scss';
.profile-container {
  background-image: map-get($instnt_app, app_background_color);
  height: calc(100vh - 123px) !important;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  .profile-container-layout {
    background: map-get($instnt_app, fabric_black_rgb) !important;
    border: 1px solid map-get($instnt_app, fabric_white);
    display: flex;
    flex-direction: column;
    min-height: 400px;
    max-height: 600px;
    padding: 20px 30px;
    border-radius: 16px;

    -webkit-box-shadow: 4px 4px 89px -29px rgba(0, 136, 187, 0.2);
    -moz-box-shadow: 4px 4px 89px -29px rgba(0, 136, 187, 0.2);
    box-shadow: 4px 4px 89px -29px rgba(0, 136, 187, 0.2);
    .profile-header {
      display: flex;
      .profile-picture {
        margin-bottom: 20px;
        img {
          min-height: 10em;
          min-width: 10em;
          max-height: 10em;
          max-width: 1oem;
        }
      }
      .login-vc-success-header {
        padding-left: 10%;
        font-family: map-get($instnt_app, font_fenwick_regular);
        font-weight: map-get($instnt_app, font_weight);
        color: map-get($instnt_app, fabric_white);
        .login-vc-success-header-text {
          font-size: 20px;
          .acmebank-color {
            color: map-get($instnt_app, fabric_tulip_pink);
          }
        }
        .middle-text {
          font-size: 40px;
          .acmebank-color {
            color: map-get($instnt_app, fabric_tulip_pink);
          }
        }
        .login-vc-success-login-text {
          font-size: 14px;
        }
      }
    }
    .profile-body {
      padding: 30px 0;
      .account-number,
      .email,
      .name {
        height: 34px;
        margin-bottom: 10px;
        padding: 8px;
        border: 1px solid map-get($instnt_app, fabric_white);
        border-radius: 8px;
        .label {
          color: map-get($instnt_app, fabric_white);
          font-size: 16px;
          padding: 1%;
        }
        .value {
          color: map-get($instnt_app, fabric_white);
          font-size: 14px;
          font-family: map-get($instnt_app, font_fenwick_regular);
          padding-left: 8px;
          font-weight: map-get($instnt_app, font_weight);
        }
      }
    }
  }
}

@media (max-width: 780px) {
  // .login-vc-success-header {
  //   h1 {
  //     font-size: 18pt !important;
  //   }
  // }
}

@media (max-width: 767px) {
  .profile-container {
    height: 78vh !important;
  }
}

@media screen and (max-width: 400px) {
  .profile-container {
    padding-left: 2%;
    padding-right: 2%;
    .profile-container-layout {
      padding: 20px 20px;
      .profile-header {
        flex-direction: column;
        .profile-picture {
          display: flex;
          justify-content: center;
        }
        .login-vc-success-header {
          padding-left: unset;
        }
      }
      .profile-body {
        .account-number {
          display: flex;
          height: 50px;
          .label {
            width: 80%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .profile-container {
    // height: unset !important;
    .profile-container-layout {
      .profile-header {
        flex-direction: column;
        .profile-picture {
          display: flex;
          justify-content: center;
        }
        .login-vc-success-header {
          padding-left: unset;
        }
      }
      .profile-body {
        .account-number {
          display: flex;
          height: 50px;
          .label {
            width: 80%;
          }
        }
      }
    }
  }
}
