.header {
  width: 100%;
  position: fixed;
  display: contents;
}

.header-container {
  height: 64px;
  margin: 26px 96px 26px 96px;
  display: flex;
  align-items: center;
  padding: 10px 12px 10px 30px;
  border-radius: 100px;
  background-color: #1e3079;
  .logo-container {
    display: flex;
    min-width: 28%;
  }
  .menu-container {
    display: flex;
    align-items: center;
    min-width: 70%;
    justify-content: space-between;

    .top-menu-middle-section {
      display: flex;
      .menu-item {
        font-size: 14px;
        font-weight: 500;
        font-family: monospace;
        display: inline-block;
        padding-right: 22px;
      }

      .menu-item.contact-us {
        padding-left: 160px;
      }

      .menu-item .contact-us-button {
        border: 1px solid white;
        padding: 12px 30px;
        border-radius: 100px;
        a {
          color: white;
        }
      }

      .menu-item .contact-us-button:hover {
        background-color: #fad559;
        color: black;
      }

      .menu-item .login-button {
        border: 1px solid white;
        padding: 12px 30px;
        border-radius: 100px;
        background-color: #fad559;
        color: #1e3079;
        .arrow {
          padding-left: 4px;
          font-size: 16px;
        }
      }

      .menu-item .login-button:hover {
        background-color: #fad559;
        color: #1e3079;
      }
    }

    .top-menu-last-section .menu-item {
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
      padding-right: 22px;
    }
  }
}

.top-menu-nav .top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/** Menu Link color **/
.menu-item a {
  color: white;
}

.menu-item a:hover {
  color: #fad559;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: absolute;
  width: 36px;
  height: 30px;
  right: 130px;
  top: 42px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #fad559;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #1e3079;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  right: 10px !important;
  top: 90px !important;
  height: 300px !important;
  width: 260px !important;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 0.5em 0.5em 0;
  font-size: 1.15em;
  border: 1px solid #1e3079;
  border-radius: 28px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: white;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  .workflow-setting-screen {
    .setting {
      color: #5264b4;
    }
    .setting:hover {
      color: #fad559;
    }
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
  font-family: monospace;
}

/* Styling of overlay */
.bm-overlay {
  left: 0px;
  top: 0px;
  opacity: 0 !important;
}

.et_mobile_menu a {
  display: none;
  padding: 10px 5%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  color: #5264b4;
}

.et_mobile_menu a:hover {
  color: #fad559;
}

.et_mobile_menu.hide {
  display: none;
}

.workflow-setting-screen {
  .setting {
    color: white;
  }
  .setting:hover {
    cursor: pointer;
  }
}

/** Setting Screen **/

.workflow-setting-screen-description {
  .MuiDialog-paper {
    color: #fafbfc !important;
    background-color: #1e3079 !important;
    border: 1px solid #fafbfc;
    border-radius: 10px;
    .MuiDialogTitle-root {
      color: #fafbfc !important;
    }
    .MuiDialogContent-root {
      padding: unset !important;
      overflow: hidden;
      .MuiFormControlLabel-label {
      }
      .MuiInputBase-root {
        background-color: #fafbfc !important;
      }
    }
    .MuiDialogActions-root {
      display: contents !important;
      .MuiButtonBase-root {
        color: #fafbfc !important;
        text-transform: unset !important;
        border: 1px solid #fafbfc;
        background-color: unset !important;
      }
      .MuiButtonBase-root:hover {
        color: black !important;
        border: 1px solid #fafbfc !important;
        background-color: #fad559 !important;
      }
    }
  }
}

input:-webkit-autofill {
  background-color: unset !important;
}

.MuiFilledInput-input:-webkit-autofill,
.MuiFilledInput-input:-webkit-autofill:focus,
.MuiFilledInput-input:-webkit-autofill:hover {
  // -webkit-text-fill-color: #fafbfc;
  // -webkit-box-shadow: 0 0 0px 1000px #fafbfc inset;
}

.MuiInputBase-root:-webkit-autofill {
  background-color: black !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #fafbfc !important;
  .MuiStepIcon-text {
    fill: black !important;
  }
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #fafbfc !important;
}

.MuiStepLabel-label {
  color: #fafbfc !important;
}
.MuiStepIcon-text {
  fill: black !important;
}
.MuiButton-label {
  color: #fafbfc !important;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  .MuiButton-label {
    color: #fafbfc !important;
  }
}

.MuiButton-contained {
  .MuiButton-label {
    color: black !important;
  }
}
.MuiButton-containedPrimary {
  color: black !important;
  background: #fafbfc !important;
}

.MuiStepIcon-text {
  fill: #fafbfc !important;
}

.MuiSvgIcon-root.MuiSelect-icon {
  color: #fafbfc !important;
}

.MuiTypography-h6 {
  display: flex !important;
  justify-content: center !important;
  color: #000 !important;
  font: 400 24px / 32px monospace !important;
  letter-spacing: -0.02em !important;
  padding: unset !important;
  margin-bottom: 16px !important;
}

.MuiFormLabel-root {
  color: black !important;
}

.MuiFormControl-root {
  .MuiOutlinedInput-notchedOutline {
    border-color: #fafbfc !important;
  }
  .MuiOutlinedInput-notchedOutline:focus {
    border-color: #fafbfc !important;
  }
  .Mui-error {
    color: rgb(211, 47, 47) !important ;
    .MuiOutlinedInput-notchedOutline {
      border-color: rgb(211, 47, 47) !important ;
    }
    .MuiOutlinedInput-notchedOutline:focus {
      border-color: rgb(211, 47, 47) !important ;
    }
  }
}

.MuiFormLabel-root:hover {
  color: #fafbfc !important;
}
.MuiDialogContent-root > .text-margin > .MuiFormControl-root {
  width: 420px;
  .MuiInputBase-input {
    padding: 25px 12px 8px !important;
  }
}

.MuiDialogContent-root
  > .text-margin
  > .MuiFormControl-root
  > .MuiInputBase-root:after {
  border-bottom: 2px solid #fafbfc !important;
}

.text-margin {
  margin-bottom: 20px;
}

.MuiPaper-root {
  padding: 24px !important;
}

/**-----**/

@media (min-width: 1450px) and (max-width: 1920px) {
  .logo-container {
    min-width: 36% !important;
  }
}

@media (min-width: 1420px) and (max-width: 1440px) {
  .menu-item.contact-us {
    padding-left: 130px !important;
  }
}

@media (min-width: 1410px) and (max-width: 1420px) {
  .menu-item.contact-us {
    padding-left: 120px !important;
  }
}

@media (min-width: 1380px) and (max-width: 1410px) {
  .menu-item.contact-us {
    padding-left: 100px !important;
  }
}

@media (min-width: 1350px) and (max-width: 1380px) {
  .menu-item.contact-us {
    padding-left: 80px !important;
  }
}

@media (min-width: 1220px) and (max-width: 1350px) {
  .menu-item.contact-us {
    padding-left: unset !important;
  }
  .logo-container {
    min-width: 15% !important;
  }
}

@media (min-width: 1150px) and (max-width: 1220px) {
  .menu-item.contact-us {
    padding-left: unset !important;
  }
  .logo-container {
    min-width: 20% !important;
  }
}

@media only screen and (max-width: 1150px) {
  .bm-burger-button {
    display: block;
  }

  .top-menu-nav {
    display: none;
  }

  .side-menu-nav {
    display: none;
  }

  .MuiDialogContent-root > .text-margin > .MuiFormControl-root {
    width: 296px;
  }
}

@media only screen and (max-width: 778px) {
  .header-container {
    margin: 14px 24px 14px 24px !important;
  }
  .bm-burger-button {
    right: 56px !important;
    top: 32px !important;
  }
}

@media only screen and (max-width: 570px) {
  .workflow-setting-screen-description {
    .MuiDialog-paper {
      .MuiDialogTitle-root {
        padding-top: 16px !important;
      }
      .MuiDialogContent-root {
        //padding: 16px !important;
      }
      .MuiDialogActions-root {
        .MuiButtonBase-root {
          margin: 16px !important;
        }
      }
    }
  }
}
