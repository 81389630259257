.signup-page {
  .signup-page-container {
    display: flex;
    padding-left: 96px;
    padding-right: 96px;
    flex-flow: column;
    height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
    .onpage-message {
      display: flex;
      justify-content: center;
      margin: 10px 20px 0 20px;
      .messageSpan {
        font-family: monospace;
        letter-spacing: -0.04em;
        font-weight: 500;
        font-size: 14px;
      }
      .hide {
        display: none !important;
      }

      .alert {
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
      }

      .alert-danger {
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
      }
    }
    .MuiBox-root {
      .MuiStepLabel-label {
        display: flex;
        flex-flow: column;
        .headtext {
          font-family: monospace;
          font-size: 16px;
          letter-spacing: -0.02em;
          font-weight: 500;
          color: #1e3079 !important;
        }
        .subtext {
          font-family: monospace;
          font-size: 14px;
          letter-spacing: -0.04em;
          font-weight: 500;
          color: #5264b4 !important;
        }
      }
      .MuiStepLabel-label.Mui-disabled {
        color: rgba(0, 0, 0, 0.38) !important;
        .headtext {
          opacity: 0.4;
        }

        .subtext {
          opacity: 0.4;
        }
      }
      .MuiStepIcon-root.Mui-active {
        color: #fad559 !important;
      }
      .MuiStepIcon-root.Mui-completed {
        color: #fad559 !important;
      }
    }

    .step-section {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .step-section-include-footer {
        flex-flow: column;
        font-family: monospace;
        text-align: center;
        color: #ffffff1a;
        margin: 20px;
        border-radius: 28px;
        border: 1px solid #eef0f2;
        box-shadow: 0px 12px 24px 0px #b8c3f12e;
        min-width: 40%;
        .progress-stepper-next-back-section {
          margin-bottom: 20px;
          .progress-stepper-next-back {
            display: flex;
            justify-content: center;
            .MuiButton-containedPrimary {
              color: #ffffff !important;
              background-color: #1e3079 !important;
              border-radius: 100px;
              border: 1px solid #1e3079;
              margin-left: 2%;
              margin-right: 2%;
              min-width: 100px;
              .MuiButton-contained.Mui-disabled {
                color: #1e3079 !important;
                background-color: unset !important;
              }
              .MuiButton-label {
                font-family: monospace;
                font-weight: 600;
                letter-spacing: -0.02em;
                font-size: 14px;
                text-transform: none !important;
                color: #ffffff !important;
              }
            }
            .MuiButton-containedPrimary.next-button-create {
              background-color: #fad559 !important;
              border: 1px solid #fad559 !important;
              .MuiButton-label {
                color: #191a1c !important;
              }
            }
            .MuiButton-contained.Mui-disabled {
              color: #1e3079 !important;
              background-color: unset !important;
              border-radius: 100px;
              border: 1px solid #1e3079;
              margin-left: 2%;
              margin-right: 2%;
              min-width: 100px;
              .MuiButton-contained.Mui-disabled {
                color: #1e3079 !important;
                background-color: unset !important;
              }
              .MuiButton-label {
                font-family: monospace;
                font-weight: 600;
                letter-spacing: -0.02em;
                font-size: 14px;
                text-transform: none !important;
                color: #1e3079 !important;
              }
            }
          }
        }
      }
    }

    .signup-loader-container {
      display: flex;
      position: relative;
      top: 50%;
      left: 50%;
    }

    .separator {
      text-align: center;
      h2 {
        font-family: monospace;
        font-weight: 600;
        font-size: 32px;
        letter-spacing: -0.02em;
        color: #1e3079;
      }
    }

    .signup-page-vc-container {
      font-family: monospace;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: -0.02em;
      color: #1e3079;
      .MuiButton-root {
        display: flex;
        border: 2px solid #1e3079;
        background-color: #5264b4;
        margin-bottom: 2%;
        margin-left: 20%;
        margin-right: 20%;
        .MuiAvatar-root {
          .MuiAvatar-img {
            width: 40px;
          }
        }
      }
      .deep-link-help-text {
        color: #5264b4;
        font-family: monospace;
        letter-spacing: -0.04em;
        font-size: 14px;
      }
    }

    .loader-text {
      font-family: monospace;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: -0.02em;
      color: #1e3079;
    }
  }
}

@media only screen and (max-width: 778px) {
  .signup-page-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: calc(100vh - 200px) !important;
    .step-section-include-footer {
      margin: 10px !important;
      .step-wrapper {
        padding: 8px !important;
      }
    }
    .signup-loader-container {
      top: 45% !important;
      left: 45% !important;
    }
    .signup-page-vc-container {
      h2 {
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .signup-page-container {
    padding-left: unset !important;
    padding-right: unset !important;
    height: calc(100vh - 200px) !important;
    .loader-text {
      margin-top: auto !important;
      margin-bottom: auto !important;
      margin-left: 24px !important;
      margin-right: 24px !important;
      text-align: center;
    }
    .step-section-include-footer {
      width: 90% !important;
      margin: 10px !important;
    }
  }
}
