.feature-wrapper-welcome {
  display: flex;
  height: calc(100vh - 120px);
  padding-left: 96px;
  padding-right: 96px;
  overflow: auto;
  justify-content: center;
  .content {
    width: 70%;
    .welcome {
      .amount-name-row {
        display: flex;
        justify-content: space-around;
        .name-box,
        .amount-box {
          border: 1px solid #5264b4;
          border-radius: 15px;
          padding: 30px 40px;
          width: 40%;

          :nth-child(1) {
            font-size: 24px;
            font-family: monospace;
            letter-spacing: -0.02em;
            font-weight: 700;
            color: #1e3079;
            padding-bottom: 10px;
          }
          :nth-child(2) {
            font-size: 18px;
            font-family: monospace;
            letter-spacing: -0.02em;
            font-weight: 500;
            color: #1e3079;
          }
        }
      }
      .vc-box {
        border: 1px solid #ffffff;
        border-radius: 15px;
        box-shadow: 0px 12px 24px 0px #b8c3f12e;
        margin-top: 20px;
        width: 100%;
        .vc-box-container {
          text-align: center;
          padding-top: 40px;
          padding-bottom: 40px;
          .vc-invitation {
            div {
              text-align: center;
              b {
                color: #1e3079;
                font-family: monospace;
                letter-spacing: -0.04em;
                font-weight: 500 !important;
                font-size: 20px;
              }
            }
          }
          .text-center {
            padding-bottom: 20px;
          }
          .learn-more {
            svg {
              margin-bottom: -5px;
              width: 2em;
            }
          }
          h1 {
            color: #1e3079;
            font-family: monospace;
            letter-spacing: -0.04em;
            font-weight: 700 !important;
            font-size: 32px;
          }
          p {
            color: #1e3079;
            font-family: monospace;
            letter-spacing: -0.04em;
            font-weight: 500 !important;
            font-size: 18px;
          }
          .vc-invitation {
            a {
              color: #191a1c;
            }
            div {
              color: #ffffff;
            }
          }
          .btn-primary {
            border: 1px solid #5264b4 !important;
            border-radius: 100px !important;
            color: #1e3079 !important;
            background-color: #fad559 !important;
            font-weight: 500;
            padding: 8px 12px;
          }

          .btn-primary-soft {
            border: 1px solid #5264b4 !important;
            color: #1e3079 !important;
            border-radius: 100px !important;
            background-color: #fad559 !important;
            padding: 8px 12px;
          }

          .MuiButton-root {
            display: flex;
            border: 2px solid #1e3079;
            background-color: #5264b4;
            margin-bottom: 2%;
            margin-left: 14%;
            margin-right: 14%;
            .MuiAvatar-root {
              .MuiAvatar-img {
                width: 40px;
              }
            }
          }
          .deep-link-help-text {
            color: #5264b4;
            font-family: monospace;
            letter-spacing: -0.04em;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 778px) {
  .feature-wrapper-welcome {
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: calc(100vh - 200px) !important;
    .content {
      width: 90% !important;
      .welcome {
        .amount-name-row {
          .name-box,
          .amount-box {
            padding: 10px 20px !important;
            width: 48% !important;
            p {
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }
}
